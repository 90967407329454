// Code for HeatPanel component
import React, { useEffect } from 'react';
import { PanelData, PanelProps } from '@grafana/data';
import { PanelOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import h337 from 'heatmap.js';
import { Styles } from '../../styles';

// Define DataPoint type
interface DataPoint {
  x: number;
  y: number;
  value: number;
  radius: number;
}

// Properties
interface Props extends PanelProps<PanelOptions> {}

function extractHeatmapData(data: PanelData): DataPoint[] {
  if (data.series && data.series[0] && data.series[0].fields) {
    const fields = data.series[0].fields;
    const xPoints = fields.find(field => field.name === 'x')?.values;
    const yPoints = fields.find(field => field.name === 'y')?.values;
    const valueValues = fields.find(field => field.name === 'value')?.values;
    const radiusValues = fields.find(field => field.name === 'radius')?.values;

    if (xPoints && yPoints && valueValues && radiusValues) {
      return valueValues.map((value, index) => {
        return {
          x: xPoints[index],
          y: yPoints[index],
          value: valueValues[index],
          radius: radiusValues[index]
        };
      });
    }
  }
  return [];
}

export const HeatPanel: React.FC<Props> = ({ id, options, data, width, height }) => {
  const styles: ReturnType<typeof Styles> = useStyles2(Styles);

  useEffect(() => {
    const container = document.querySelector(`.heatmap-container-${id}`) as HTMLElement;
    const heatmapInstance = h337.create({
      container,
      opacity: 0.5,
      gradient: {
        '.3': 'blue',
        '.5': 'green',
        '.80': 'red'
      }
    });

    heatmapInstance.setData({ 
      min: 0, 
      max: 1, 
      data: extractHeatmapData(data)
    }); 

    return () => {
      heatmapInstance.setData({ min: 0, max: 0, data: [] }); 
    };
  }, [data, id]);

  // HTML elements
  return (
    <div
      className={cx(
        styles.wrapper,
        `heatmap-container-${id}`,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <img
        className={styles.img}
        src={options.url}
        width={options.width}
        height={options.height}
      />
    </div>
  );
};

