import { PanelPlugin } from '@grafana/data';
import { PanelOptions } from './types';
import { HeatPanel } from './components';

/**
 * Panel plugin
 */


export const plugin = new PanelPlugin<PanelOptions>(HeatPanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'url',
      name: 'Image URL',
      defaultValue: 'https://',
    })
    .addTextInput({
      path: 'width',
      name: 'width',
      defaultValue: '1200',
    })
    .addTextInput({
      path: 'height',
      name: 'height',
      defaultValue: '800',
    })


});
