import { css } from '@emotion/css';


export const Styles = () => {
    return {
      wrapper: css`
        font-family: Open Sans;
        position: relative;

      `,
      img: css`
        position: absolute;
        top: 0;
        left: 0;
      `,
    };
  };

